var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ]
    },
    [
      _c("app-navigation", {
        attrs: { title: _vm.$t("settings.import-export.import-export") }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("settings.import-export.select-modules"),
            visible: _vm.visibleExportDialog,
            "lock-scroll": false
          },
          on: {
            "update:visible": function($event) {
              _vm.visibleExportDialog = $event
            }
          }
        },
        [
          _c(
            "div",
            [
              _c(
                "el-checkbox",
                {
                  on: { change: _vm.selectAllExportNodes },
                  model: {
                    value: _vm.selectAllExportNodesCheckbox,
                    callback: function($$v) {
                      _vm.selectAllExportNodesCheckbox = $$v
                    },
                    expression: "selectAllExportNodesCheckbox"
                  }
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("Select All")) + "\n      "
                  )
                ]
              ),
              _c("el-tree", {
                ref: "exportTree",
                attrs: {
                  data:
                    _vm.$i18n.locale == "tr"
                      ? _vm.exportListTr
                      : _vm.exportList,
                  "show-checkbox": "",
                  "node-key": "id",
                  "check-on-click-node": "",
                  "default-expand-all": "",
                  "expand-on-click-node": false
                },
                on: { "check-change": _vm.checkChangeExports },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var node = ref.node
                      var data = ref.data
                      return _c("span", { staticClass: "custom-tree-node" }, [
                        _c("span", [
                          _c("i", { class: data.icon }),
                          _vm._v(" " + _vm._s(node.label))
                        ]),
                        _c("span", [_vm._v(_vm._s(data.description))])
                      ])
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticStyle: { "margin-top": "20px" },
              attrs: { type: "primary" },
              on: { click: _vm.exportJson }
            },
            [_vm._v(_vm._s(_vm.$t("settings.import-export.export")))]
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("settings.import-export.select-modules"),
            visible: _vm.visibleImportDialog,
            "lock-scroll": false
          },
          on: {
            "update:visible": function($event) {
              _vm.visibleImportDialog = $event
            }
          }
        },
        [
          _c(
            "el-checkbox",
            {
              on: { change: _vm.selectAllImportNodes },
              model: {
                value: _vm.selectAllImportNodesCheckbox,
                callback: function($$v) {
                  _vm.selectAllImportNodesCheckbox = $$v
                },
                expression: "selectAllImportNodesCheckbox"
              }
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("Select All")) + "\n    ")]
          ),
          _c("el-tree", {
            ref: "importTree",
            attrs: {
              data: _vm.importList,
              "default-expand-all": "",
              "check-on-click-node": "",
              "show-checkbox": "",
              "node-key": "id"
            },
            on: { "check-change": _vm.checkChangeImports },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var node = ref.node
                  var data = ref.data
                  return _c("span", { staticClass: "custom-tree-node" }, [
                    _c("span", [
                      _c("i", { class: data.icon }),
                      _vm._v(" " + _vm._s(node.label))
                    ]),
                    _c("span", [_vm._v(_vm._s(data.description))])
                  ])
                }
              }
            ])
          }),
          _c(
            "el-button",
            {
              staticStyle: { "margin-top": "20px" },
              attrs: { type: "primary" },
              on: { click: _vm.syncImportData }
            },
            [_vm._v(_vm._s(_vm.$t("settings.import-export.import")))]
          )
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-upload",
                {
                  ref: "upload",
                  staticStyle: {
                    display: "flex",
                    flex: "1",
                    "flex-flow": "column"
                  },
                  attrs: {
                    action: "upload.php",
                    "on-change": _vm.handleChange,
                    "auto-upload": false,
                    "show-file-list": false,
                    "file-list": _vm.fileList
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "avatar-uploader",
                      staticStyle: {
                        display: "flex",
                        flex: "1",
                        "justify-content": "flex-start",
                        "align-items": "center"
                      },
                      attrs: { slot: "trigger" },
                      slot: "trigger"
                    },
                    [
                      _c("img", {
                        staticClass: "avatar-uploader-icon",
                        attrs: {
                          src: require("../../../assets/icons/import.png")
                        }
                      }),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "font-size": "x-large",
                            color: "#8c939d",
                            "font-weight": "bold",
                            "margin-left": "50px"
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("settings.import-export.import"))
                          )
                        ]
                      )
                    ]
                  )
                ]
              )
            ],
            1
          ),
          _c("el-col", { attrs: { span: 12 } }, [
            _c(
              "div",
              {
                staticClass: "avatar-uploader",
                staticStyle: {
                  display: "flex",
                  flex: "1",
                  "justify-content": "flex-start",
                  "align-items": "center"
                },
                on: {
                  click: function($event) {
                    _vm.visibleExportDialog = !_vm.visibleExportDialog
                  }
                }
              },
              [
                _c("img", {
                  staticClass: "avatar-uploader-icon",
                  attrs: { src: require("../../../assets/icons/export.png") }
                }),
                _c(
                  "span",
                  {
                    staticStyle: {
                      "font-size": "x-large",
                      color: "#8c939d",
                      "font-weight": "bold",
                      "margin-left": "50px"
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("settings.import-export.export")))]
                )
              ]
            )
          ])
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            "margin-top": "10px",
            padding: "10px",
            "font-size": "medium"
          }
        },
        [
          _c("el-divider", { attrs: { "content-position": "left" } }, [
            _vm._v(_vm._s(_vm.$t("settings.import-export.important-info")))
          ]),
          _c("p", [
            _vm._v(_vm._s(_vm.$t("settings.import-export.explanation1")))
          ]),
          _c("p", [
            _vm._v(_vm._s(_vm.$t("settings.import-export.explanation2")))
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }