<template>
  <div v-loading="loading">
    <app-navigation :title="$t('settings.import-export.import-export')"></app-navigation>

    <el-dialog :title="$t('settings.import-export.select-modules')" :visible.sync="visibleExportDialog"
      :lock-scroll="false">
      <div>
        <!-- <el-button style="margin-top: 20px" @click="selectAllNodes">
    {{ $t("Select All") }}
  </el-button> -->
        <el-checkbox @change="selectAllExportNodes" v-model="selectAllExportNodesCheckbox">
          {{ $t("Select All") }}
        </el-checkbox>
        <el-tree ref="exportTree" :data="$i18n.locale == 'tr' ? exportListTr : exportList" show-checkbox node-key="id"
          check-on-click-node default-expand-all :expand-on-click-node="false" @check-change="checkChangeExports">
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <span><i :class="data.icon"></i> {{ node.label }}</span>
            <span>{{ data.description }}</span>
          </span>
        </el-tree>
      </div>

      <el-button style="margin-top: 20px" type="primary" @click="exportJson">{{
        $t("settings.import-export.export")
      }}</el-button>
    </el-dialog>

    <el-dialog :title="$t('settings.import-export.select-modules')" :visible.sync="visibleImportDialog"
      :lock-scroll="false">
      <el-checkbox @change="selectAllImportNodes" v-model="selectAllImportNodesCheckbox">
        {{ $t("Select All") }}
      </el-checkbox>
      <el-tree ref="importTree" :data="importList" @check-change="checkChangeImports" default-expand-all
        check-on-click-node show-checkbox node-key="id">
        <span class="custom-tree-node" slot-scope="{ node, data }">
          <span><i :class="data.icon"></i> {{ node.label }}</span>
          <span>{{ data.description }}</span>
        </span>
      </el-tree>

      <el-button style="margin-top: 20px" type="primary" @click="syncImportData">{{ $t("settings.import-export.import")
      }}</el-button>
    </el-dialog>

    <el-row :gutter="20">
      <el-col :span="12">
        <el-upload ref="upload" style="display: flex; flex: 1; flex-flow: column;" action="upload.php"
          :on-change="handleChange" :auto-upload="false" :show-file-list="false" :file-list="fileList">
          <div slot="trigger" class="avatar-uploader"
            style="display: flex; flex: 1; justify-content: flex-start; align-items: center">
            <img class="avatar-uploader-icon" src="../../../assets/icons/import.png">
            <!-- <i class="fa fa-file-import avatar-uploader-icon"></i> -->
            <span style="font-size: x-large; color: #8c939d; font-weight: bold; margin-left: 50px;">{{
              $t("settings.import-export.import") }}</span>
          </div>
        </el-upload>
      </el-col>
      <el-col :span="12">
        <div class="avatar-uploader" style="display: flex; flex: 1; justify-content: flex-start; align-items: center"
          @click="visibleExportDialog = !visibleExportDialog">
          <img class="avatar-uploader-icon" src="../../../assets/icons/export.png">
          <!-- <i class="fa fa-file-export avatar-uploader-icon"></i> -->
          <span style="font-size: x-large; color: #8c939d; font-weight: bold; margin-left: 50px;">{{
            $t("settings.import-export.export")
          }}</span>
        </div>
      </el-col>
    </el-row>

    <div style="margin-top: 10px; padding: 10px; font-size: medium;">
      <el-divider content-position="left">{{
        $t("settings.import-export.important-info")
      }}</el-divider>
      <p>{{ $t("settings.import-export.explanation1") }}</p>
      <p>{{ $t("settings.import-export.explanation2") }}</p>
    </div>
  </div>
</template>

<script>
import Navigation from "@/components/Navigation";
import { className } from "@/utils/accessory-utils";
import { DRIVER_TYPE_CORECENTER } from "@/utils/driver-utils";
import { initializeWebSocket } from "@/utils/socket-utils"

export default {
  name: "index",

  components: {
    "app-navigation": Navigation
  },

  data: function () {
    return {
      visibleExportDialog: false,
      visibleImportDialog: false,
      exportList: [],
      exportListTr: [],
      importList: [],
      fileList: [],
      defaultProps: {
        children: "children",
        label: "label"
      },
      loading: false,
      selectAllExportNodesCheckbox: false,
      selectAllImportNodesCheckbox: false,
    };
  },

  computed: {
    isConnected() {
      return this.$store.getters.isConnected;
    },
  },

  watch: {
    isConnected(value) {
      if (value) {
        this.fetchData();
      }
    }
  },

  methods: {
    className,

    getImgPath: function (icon) {
      return require(`../../../assets/icons/` + icon + `.png`);
    },

    checkChangeExports(node, nodeSelected, subSelected) {
      console.log("checkChange", node, nodeSelected, subSelected);

      if (node.id === "scenes" && nodeSelected) {
        this.$refs.exportTree.setChecked("accessories", true, false);
      } else if (node.id === "accessories" && !nodeSelected) {
        this.$refs.exportTree.setChecked("scenes", false, false);
      }
    },

    selectAllExportNodes() {
      const tree = this.$refs.exportTree;
      if (this.selectAllExportNodesCheckbox) {
        const allNodeKeys = this.getAllNodeKeys(tree.data);
        tree.setCheckedKeys(allNodeKeys);
      } else {
        tree.setCheckedKeys([]);
      }
    },

    checkChangeImports(node, nodeSelected, subSelected) {
      console.log("checkChange", node, nodeSelected, subSelected);

      if (node.id === "scenes" && nodeSelected) {
        this.$refs.importTree.setChecked("accessories", true, false);
      } else if (node.id === "accessories" && !nodeSelected) {
        this.$refs.importTree.setChecked("scenes", false, false);
      }
    },

    selectAllImportNodes() {
      const tree = this.$refs.importTree;
      if (this.selectAllImportNodesCheckbox) {
        const allNodeKeys = this.getAllNodeKeys(tree.data);
        tree.setCheckedKeys(allNodeKeys);
      } else {
        tree.setCheckedKeys([]);
      }
    },

    exportJson() {
      var checkedList = this.$refs.exportTree.getCheckedNodes(true);
      console.log(checkedList);

      this.visibleExportDialog = !this.visibleExportDialog;

      var json = JSON.stringify({ ...checkedList });
      var blob = new Blob([json], { type: "application/json" });
      var url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", "backup.json");
      link.click();
    },

    handleChange(file, fileList) {
      console.log("handleChange", file, fileList);

      var self = this;

      if (window.FileReader) {
        let reader = new FileReader();
        reader.readAsText(file.raw);

        reader.onload = event => {
          let json = event.target.result;
          var importData = JSON.parse(json);

          self.importList = [...Object.values(importData)];
          self.visibleImportDialog = true;
        };

        reader.onerror = event => {
          console.log("onLoad fail");
        };
      }
    },

    syncImportData() {
      console.log("syncImportData start");
      var checkedList = this.$refs.importTree.getCheckedNodes();

      this.loading = true;
      let asynImportData = [];
      checkedList.forEach(importData => {
        if (importData.data) {
          importData.data.forEach(data => {
            asynImportData.push(
              this.$store.dispatch(data.dispatch, data.payload)
            );
          });
        }
      });

      this.visibleImportDialog = false;

      Promise.all(asynImportData)
        .then(res => {
          this.loading = false;
          console.log("syncImportData end s");
        })
        .catch(err => {
          this.loading = false;
          console.log("syncImportData end e");
        });
    },

    getAllNodeKeys(nodes) {
      return nodes.reduce((keys, node) => {
        keys.push(node.id);
        if (node.children) {
          keys = keys.concat(this.getAllNodeKeys(node.children));
        }
        return keys;
      }, []);
    },

    fetchSettingsData() {
      return Promise.all([
        this.$store.dispatch("getDrivers"),
        this.$store.dispatch("getAccessories"),
        this.$store.dispatch("getRooms"),
        this.$store.dispatch("getSections"),
        this.$store.dispatch("getIPCameras"),
        this.$store.dispatch("getScenes"),
        this.$store.dispatch("getTriggers"),
        this.$store.dispatch("getContacts"),
        this.$store.dispatch("getIntercomSettings"),
        this.$store.dispatch("getGeneralSettings"),
        this.$store.dispatch("getNetworkSettings"),
        this.$store.dispatch("getSecuritySettings"),
        this.$store.dispatch("getWidgets")
      ]);
    },
    
    async fetchData() {
      await this.fetchSettingsData()
      .then(value => {
        console.log("promise all", value);

        let accessories = this.$store.getters.accessories
          .filter(accessory => {
            var driver = this.$store.getters.drivers.find(
              driver => driver.id == accessory.driverId
            );
            return driver && driver.driverType != DRIVER_TYPE_CORECENTER;
          })
          .map(accessory => {
            return {
              type: className(accessory.category),
              properties: { ...accessory }
            };
          });

        let ipcameras = this.$store.getters.ipcameras.map(accessory => {
          return {
            type: className(accessory.category),
            properties: { ...accessory }
          };
        });

        this.exportList = [
          {
            id: "devices",
            label: 'Devices',
            icon: "fa fa-plug fa-fw",
            children: [
              {
                id: "accessories",
                label: "Drivers And Accessories",
                icon: "fa fa-power-off fa-fw",
                description: `${accessories.length} Accessory(s) and ${this.$store.getters.drivers.length} Driver(s)`,
                data: [
                  {
                    dispatch: "syncDrivers",
                    payload: [...this.$store.getters.drivers]
                  },
                  { dispatch: "syncAccessories", payload: [...accessories] }
                ]
              },

              {
                id: "rooms",
                label: "Rooms",
                icon: "fa fa-home fa-fw",
                description: `${this.$store.getters.rooms.length} Room(s)`,
                data: [
                  {
                    dispatch: "syncRooms",
                    payload: [...this.$store.getters.rooms]
                  }
                ]
              },

              {
                id: "sections",
                label: "Sections",
                icon: "fa fa-home fa-fw",
                description: `${this.$store.getters.sectionCount} Section(s)`,
                data: [
                  {
                    dispatch: "syncSections",
                    payload: [...this.$store.getters.sections]
                  }
                ]
              }
            ]
          },

          {
            id: "security",
            label: "Security",
            icon: "fa fa-lock fa-fw",
            children: [
              {
                id: "ipcameras",
                label: "IP Cameras",
                icon: "fa fa-video fa-fw",
                description: `${ipcameras.length} IP Camera(s)`,
                data: [{ dispatch: "syncIPCameras", payload: [...ipcameras] }]
              }
            ]
          },

          {
            id: "automations",
            label: "Scenes And Automations",
            icon: "fa fa-play-circle fa-fw",
            children: [
              {
                id: "scenes",
                label: "Scenes",
                icon: "fa fa-play-circle fa-fw",
                description: `${this.$store.getters.scenes.length} Scene(s)`,
                data: [
                  {
                    dispatch: "syncScenes",
                    payload: [...this.$store.getters.scenes]
                  }
                ]
              },
              {
                id: "triggers",
                label: "Triggers",
                icon: "fa fa-bell fa-fw",
                description: `${this.$store.getters.triggers.length} Trigger(s)`,
                data: [
                  {
                    dispatch: "syncTriggers",
                    payload: [...this.$store.getters.triggers]
                  }
                ]
              }
            ]
          },

          {
            id: "intercom",
            label: "Intercom",
            icon: "fab fa-intercom fa-fw",
            children: [
              {
                id: "contacts",
                label: "Contacts",
                icon: "fa fa-user fa-fw",
                description: `${this.$store.getters.contacts.length} Contact(s)`,
                data: [
                  {
                    dispatch: "syncContacts",
                    payload: [...this.$store.getters.contacts]
                  }
                ]
              },

              {
                id: "intercomSettings",
                label: "Intercom Settings",
                icon: "fa fa-cog fa-fw",
                data: [
                  {
                    dispatch: "saveIntercomSettings",
                    payload: { ...this.$store.getters.intercomSettings }
                  }
                ]
              }
            ]
          },

          {
            id: "ui",
            label: "UI Settings",
            icon: "fas fa-quidditch fa-fw",
            children: [
              {
                id: "widgets",
                label: "Widgets",
                icon: "fas fa-clipboard-list fa-fw",
                description: `${this.$store.getters.widgets.length} Widget(s)`,
                data: [
                  {
                    dispatch: "editWidgets",
                    payload: [...this.$store.getters.widgets]
                  }
                ]
              }
            ]
          },

          {
            id: "settings",
            label: "System Settings",
            icon: "fa fa-cog fa-fw",
            children: [
              {
                id: "general",
                label: "General Settings",
                icon: "fa fa-sliders-h fa-fw",
                data: [
                  {
                    dispatch: "saveGeneralSettings",
                    payload: {
                      project: { ...this.$store.getters.project },
                      screenSaver: { ...this.$store.getters.screenSaver },
                      weather: { ...this.$store.getters.weather },
                      device: { ...this.$store.getters.device },
                      ringtone: { ...this.$store.getters.ringtone },
                      dateTime: { ...this.$store.getters.dateTime }
                    }
                  }
                ]
              },

              {
                id: "network",
                label: "Network Settings",
                icon: "fa fa-network-wired fa-fw",
                data: [
                  {
                    dispatch: "saveNetworkSettings",
                    payload: { ...this.$store.getters.ethernetSettings }
                  }
                ]
              }
            ]
          }
        ];

        this.exportListTr = [
          {
            id: "devices",
            label: 'Cihazlar',
            icon: "fa fa-plug fa-fw",
            children: [
              {
                id: "accessories",
                label: "Sürücüler ve Aksesuarlar",
                icon: "fa fa-power-off fa-fw",
                description: `${accessories.length} Aksesuar ve ${this.$store.getters.drivers.length} Sürücü`,
                data: [
                  {
                    dispatch: "syncDrivers",
                    payload: [...this.$store.getters.drivers]
                  },
                  { dispatch: "syncAccessories", payload: [...accessories] }
                ]
              },

              {
                id: "rooms",
                label: "Odalar",
                icon: "fa fa-home fa-fw",
                description: `${this.$store.getters.rooms.length} Oda`,
                data: [
                  {
                    dispatch: "syncRooms",
                    payload: [...this.$store.getters.rooms]
                  }
                ]
              },

              {
                id: "sections",
                label: "Bölümler",
                icon: "fa fa-home fa-fw",
                description: `${this.$store.getters.sectionCount} Bölüm`,
                data: [
                  {
                    dispatch: "syncSections",
                    payload: [...this.$store.getters.sections]
                  }
                ]
              }
            ]
          },

          {
            id: "security",
            label: "Güvenlik",
            icon: "fa fa-lock fa-fw",
            children: [
              {
                id: "ipcameras",
                label: "IP Kamera",
                icon: "fa fa-video fa-fw",
                description: `${ipcameras.length} IP Kamera`,
                data: [{ dispatch: "syncIPCameras", payload: [...ipcameras] }]
              }
            ]
          },

          {
            id: "automations",
            label: "Senaryolar ve Otomasyonlar",
            icon: "fa fa-play-circle fa-fw",
            children: [
              {
                id: "scenes",
                label: "Senaryolar",
                icon: "fa fa-play-circle fa-fw",
                description: `${this.$store.getters.scenes.length} Senaryo`,
                data: [
                  {
                    dispatch: "syncScenes",
                    payload: [...this.$store.getters.scenes]
                  }
                ]
              },
              {
                id: "triggers",
                label: "Tetikleyiciler",
                icon: "fa fa-bell fa-fw",
                description: `${this.$store.getters.triggers.length} Tetikleyici`,
                data: [
                  {
                    dispatch: "syncTriggers",
                    payload: [...this.$store.getters.triggers]
                  }
                ]
              }
            ]
          },

          {
            id: "intercom",
            label: "Interkom",
            icon: "fab fa-intercom fa-fw",
            children: [
              {
                id: "contacts",
                label: "Rehber",
                icon: "fa fa-user fa-fw",
                description: `${this.$store.getters.contacts.length} Rehber Kaydı`,
                data: [
                  {
                    dispatch: "syncContacts",
                    payload: [...this.$store.getters.contacts]
                  }
                ]
              },

              {
                id: "intercomSettings",
                label: "Interkom Ayarları",
                icon: "fa fa-cog fa-fw",
                data: [
                  {
                    dispatch: "saveIntercomSettings",
                    payload: { ...this.$store.getters.intercomSettings }
                  }
                ]
              }
            ]
          },

          {
            id: "ui",
            label: "UI Ayarları",
            icon: "fas fa-quidditch fa-fw",
            children: [
              {
                id: "widgets",
                label: "Widget",
                icon: "fas fa-clipboard-list fa-fw",
                description: `${this.$store.getters.widgets.length} Widget`,
                data: [
                  {
                    dispatch: "editWidgets",
                    payload: [...this.$store.getters.widgets]
                  }
                ]
              }
            ]
          },

          {
            id: "settings",
            label: "Sistem Ayarları",
            icon: "fa fa-cog fa-fw",
            children: [
              {
                id: "general",
                label: "Genel Ayarlar",
                icon: "fa fa-sliders-h fa-fw",
                data: [
                  {
                    dispatch: "saveGeneralSettings",
                    payload: {
                      project: { ...this.$store.getters.project },
                      screenSaver: { ...this.$store.getters.screenSaver },
                      weather: { ...this.$store.getters.weather },
                      device: { ...this.$store.getters.device },
                      ringtone: { ...this.$store.getters.ringtone },
                      dateTime: { ...this.$store.getters.dateTime }
                    }
                  }
                ]
              },

              {
                id: "network",
                label: "Network Ayarları",
                icon: "fa fa-network-wired fa-fw",
                data: [
                  {
                    dispatch: "saveNetworkSettings",
                    payload: { ...this.$store.getters.ethernetSettings }
                  }
                ]
              }
            ]
          }
        ];

        this.loading = false;
      })
      .catch(reason => {
        this.loading = false;
      });
    }
  },

  created() {
    this.loading = true;
    if (!this.isConnected) {
      initializeWebSocket()
    }
    this.fetchData()
  }
};
</script>

<style scoped>
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

.avatar-uploader {
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 4px 4px 10px rgba(184, 184, 184, 0.5);
  opacity: 1;
  background-color: rgba(252, 252, 252, 1);
  height: 325px;
}

.avatar-uploader:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 60px;
  color: #00BFD7;
  margin-left: 50px;
}
</style>
